import { useRef } from "react";
import './styles.sass'
import Logo from '../../assets/images/logo.png'
import Navigation from '../../components/Navigation';
import SocialLinks from '../../components/SocialLinks';

interface HeaderProps{
 type?: string
}
export default function Header({ type }: HeaderProps){
  const headerRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    headerRef?.current?.classList.toggle('is-visible');
  }

  return(
    <>
      <i className="mobile-nav fa-solid fa-bars" onClick={toggleMenu}></i>
      <div className="header" ref={headerRef} onClick={toggleMenu}>
        <i className="header__close fa-solid fa-close"></i>
        <img src={Logo} className="logo" alt="DinoSound" width="300px" />
        <div className="header__nav">
          <Navigation type={type} />
          <SocialLinks />
        </div>
      </div>
    </>
  )
}

import { useRef, useState } from "react"
import './styles.sass'

interface VideoProps{
  src: string,
  width: string,
  poster: string
}
export default function Video({src, width, poster}: VideoProps){
  const videoRef = useRef<HTMLVideoElement>(null)
  const playIcon = useRef<HTMLDivElement>(null)
  const [controls, setControls] = useState<boolean>(false)

  const handlePlay = () => {
    videoRef?.current?.play();
    // hide overlay play button styles, set by 'video__play-button'
    playIcon?.current?.classList.add('is-playing');
    setControls(true)
  };
  const handlePause = () => {
    videoRef?.current?.pause();
    // show overlay play button styles, set by 'video__play-button'
    playIcon?.current?.classList.remove('is-playing');
    setControls(false)
  };
  const handleToggleVideo = () => (videoRef?.current?.paused ? handlePlay() : handlePause());
  return(
    <div className="video" onClick={handleToggleVideo}>
      <video ref={videoRef} src={src} width={width} controls={controls} poster={poster} />
      <div ref={playIcon} className="video__icon">
        <i className="fa-solid fa-play"></i>
      </div>
    </div>
  )
}

import './styles.sass'
import SocialLinks from "../../components/SocialLinks"

interface LinkType{
  name: string,
  link: string
}

interface LinksProps{
  links: LinkType[],
}
export default function Links({links}: LinksProps){


  return(
    <div className="link-page">
      <div className="header-image" />
      <h1 className="main-h2">Listen to DinoSound on:</h1>
      <div className="button-links">
        {links.map(link =>
          <a 
            key={`button-links_${link.name}`}
            className="button-links__item inline-link promo-text"
            href={link.link}
            target="_blank"
            rel="noreferrer"
          >
            {link.name}
          </a>
        )}
      </div>
      <SocialLinks />
      <footer className="main-footer">
        <i className="fa-solid fa-envelope-o"></i>
        <a href="mailto:contact[at]dinosound-music.com" className="main-link main-fineprint">
          DinoSound
        </a>
        <span className="main-fineprint">-</span>
        <a href="/impressum/" className="main-link main-fineprint main-fineprint--impressum">Impressum</a>
      </footer>
    </div>
  )
}

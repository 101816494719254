import './styles.sass';
import Music from './Music';
import Booking from './Booking';
import Contact from './Contact';
import Media from './Media';
import Header from '../../components/Header';
import MovingBanner from '../../components/MovingBanner';
import image1 from '../../assets/images/epk/img_1.jpg'

function Epk() {
  return (
    <div className="epk-page">
      <Header type="epk" />
      <div className="main-content">
        <div className="epk-start">
          <MovingBanner text="New album in 2023!"/>
          <img src={image1} alt="DinoSound Promo" width="50%" />
        </div>
        <Music />
        <Media />
        <Booking />
        <Contact />
      </div>
      <footer className="main-footer">
        <a href="/" className="main-link" target="_blank">Zur DinoSound Webseite</a>
      </footer>
    </div>
  );
}

export default Epk;

import { videos } from "../../services/data"

export default function Music(){
  const mp3s = [
    {label: 'Upcoming Single "Possible Impossible"', file: 'possible.mp3'},
    {label: 'Single "Disturbia"', file: 'disturbia.mp3'},
    {label: 'Single "Pain"', file: 'pain.mp3'},
    {label: 'Single "Long Long Gone"', file: 'long_long_gone.mp3'},
  ]
  return(
    <div id="epk-music">
      <h1 className="h1">Musik</h1>
      <p className="main-text spacing-bottom-24">Am 29.06.2023 kommt unsere neues Album "State of the Art". Für einen ersten Eindruck, in welche Richtung das neue Album gehen wird, sind die letzten drei Song-Releases "Pain", "The Secrets they keep" und "Disturbia" gute Referenzen:</p>
      <div className="epk-youtube-videos">
        {videos.map(video =>
          <iframe key={video.link} title={`Youtube Video ${video.name}`} className="epk-youtube-videos__item" frameBorder="0" height="260" src={video.link} width="450"></iframe>
        )}
      </div>
      {/* <h2 className="h2">MP3 Downloads</h2>
      <ul className="epk-inline-list">
        {mp3s.map(mp3 =>
          <li key={mp3.label.trim()} className="epk-inline-list__item">
            <h2 className="main-h2">{mp3.label}</h2>
            <a className="epk-download-preview inline-link" href={`/music/${mp3.file}`} target="_blank" rel="noreferrer">
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
          
        )}
      </ul> */}
      <h2 className="h2">Pressetext Debütalbum Claudia</h2>
      <div className="epk-split-content">
        <p className="main-text">Die Berliner Rockband DinoSound startet 2018 mit der Veröffentlichung ihres Debütalbums Claudia.</p>
        <p className="main-text">Claudia ist ein weiblicher Vorname, der vor allem zwischen 1940 bis 1970 in Deutschland zu den häufigsten gehörte. Und was hat das mit Musik zu tun, mit Rock-Musik im Besonderen? Im Falle von DinoSound allerhand.</p>
        <p className="main-text">Bandgründer, Sänger und Gitarrist Florin Kerber legte keinen Wert auf einen bedeutungsschwangeren Albumtitel, sondern personalisierte das Debütalbum von DinoSound kurzerhand.</p>
        <p className="main-text">Nachdem die erste selbstbetitelte EP im Jahr 2015 vorrangig in Berlin auf Gehör stieß und man sich mit Airplays und Live-Auftritten einen Namen erspielen konnte, setzte man sich an die Produktion des ersten Albums. Ist es heutzutage auch üblich, sich mit Ghostwritern zusammenzusetzen, schrieb die Band alle Songs &amp; Texte komplett selber, nahm sie in seinem eigenen “Tausendsassa Studio” auf und ließ auch Mix und Mastering auf die Kappe von Kerber gehen.</p>
        <p className="main-text">Nachdem die vierköpfige Band seit der Gründung 2013 den ein oder anderen Besetzungswechsel verkraften musste, sind die Jungs und Mädels nun mit Stahl abgeduscht und bereit, Claudia in den musikalischen Äther der Unendlichkeit zu blasen.</p>
      </div>
      <div className="epk-split-sidebar">
        <iframe title="Spotify Player - Claudia" src="https://open.spotify.com/embed?uri=spotify:album:2Yje5eXN2MINkxZxmYKr26" width="300" height="450" frameBorder="0"></iframe>
      </div>
      </div>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.sass';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import App from './App';
import Epk from './pages/Epk';
import Start from './pages/Start';
import Impressum from './pages/Impressum';
import Links from './pages/Links';
import { links, pain, stk, disturbia } from './services/data';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Start />} />
          <Route path="/epk" element={<Epk />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/links" element={<Links links={links}/>} />
          <Route path="/04-23-pain" element={<Links links={pain}/>} />
          <Route path="/disturbia" element={<Links links={disturbia}/>} />
          <Route path="/04-23-stk" element={<Links links={stk}/>} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
);

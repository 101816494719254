import './styles.sass'

export default function MovingBanner({text}: {text: string}){
  return(
    <div className="moving-banner">
      <p className='moving-banner__text'>
        {text}
      </p>
    </div>
  )
}

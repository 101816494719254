export default function Booking(){
  const downloads = [
    {label: 'Technical Rider', file: 'dinosound_rider.pdf', download: 'dinosound_tech_rider.pdf'},
    {label: 'Logo Black', file: 'logo_black.png', download: 'dinosound_logo_black.png'},
    {label: 'Logo White', file: 'logo_white.png', download: 'dinosound_logo_white.png'},
    {label: 'Logo Gradient', file: 'dinologo-accent.png', download: 'dinosound_logo_gradient.png'},
  ]
  return(
    <div id="epk-booking">
      <h1 className="h1">Booking</h1>
      <p className="main-text spacing-bottom-24">Ihr wollt uns für eure Veranstaltung buchen oder braucht noch mehr Info's von uns, dann schreibt uns einfach ein Mail an unsere <a href="mailto:booking@dinosound-music.com" className="inline-link">Booking-Adresse</a>. Falls ihr noch unseren Tech-Rider oder Promo-Materialien benötigt, bedient euch in den folgenden Downloads und Texten.</p>
      <h2 className="h2">Info Downloads</h2>
      <ul className="epk-inline-list">
        {downloads.map((d, index) =>
          <li key={d.download + index} className="epk-inline-list__item">
            <h3 className="main-h2">{d.label}</h3>
            <a className="epk-download-preview inline-link"
              download={d.download}
              href={`/images/epk/${d.file}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-download"></i>
            </a>
          </li>
        )}
      </ul>
      <h2 className="h2">Pressetexte für Veranstaltungen</h2>
      <h3 className="main-h2">Deutsch</h3>
      <p className="main-text">DinoSound, die Berliner Rockband, geht in die nächste Runde und bringt 2023 frischen Wind in die Musikszene mit ihrem neuen Album "State of the Art". Seit 2015 rocken sie die Bühnen und haben bereits gezeigt, dass sie zu Recht zu den vielversprechendsten Nachwuchsbands gehören.</p>
      <p className="main-text">Die Band, bestehend aus Frontmann und Gitarrist Florin, Bassistin Janine,  Schlagzeuger Flo und Gitarrist Daniel, überrascht ihre Fans mit einer neuen Dimension im Sound. Denn neben Florins markantem Gesang, übernimmt nun auch Janine einen Teil der Leadvocals und sorgt für eine noch größere Abwechslung in den Songs.</p>
      <p className="main-text">Aber nicht nur die Vocals werden auf "State of the Art" neu aufgelegt, auch die Musik selbst überzeugt durch ihre abwechslungsreichen und anspruchsvollen Kompositionen. Egal ob pop-punkige Sommer-Melodien, gitarrenlastiger Hairmetal oder eher verträumter Indierock - im bandeigenen Tausendsassa-Studio schreiben, produzieren und leben die vier Berliner von DinoSound Rockmusik jeder Facette mit viel Liebe zum Detail. Auf der Bühne und in ihren Musikvideos beweisen die humorvollen Berliner, dass die energiegeladenen und abwechslungsreichen Songs nicht nur auf Platte Spaß machen.</p>
      <h3 className="main-h2">Englisch</h3>
      <p className="main-text">Get ready for the release of DinoSound's highly anticipated new long player "State of the Art"! This rock band from the vibrant city of Berlin is fronted by both a male and female lead, bringing a unique and dynamic energy to their sound.</p>
      <p className="main-text">With a blend of classic rock and a modern edge, DinoSound is set to take the music world by storm with their upcoming release. Featuring catchy, high-energy tracks and powerful vocals from both Janine and Florin, this album is sure to be a standout in 2023.</p>
      <p className="main-text">But it's not just the vocals that are being revamped on "State of the Art"; the music itself is also impressive with its diverse and challenging compositions. Whether it be pop-punk summer melodies, guitar-heavy hair metal, or dreamy indie rock - the four members of DinoSound from Berlin write, produce, and live out every facet of rock music in their self-titled 'Tausendsassa' studio with great attention to detail. On stage and in their music videos, the humorous Berliners prove that their energetic and varied songs are not only fun on record, but also on stage.</p>
    </div>
  )
}

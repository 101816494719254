import image1 from '../../assets/images/epk/img_1.jpg'
import image2 from '../../assets/images/epk/img_3.jpg'
export default function About(){
  return(
    <div id="about">
      <h1 className="h1">About</h1>
      <div className="about-view">
        <div>
          <p className="main-text">
            DinoSound are four Berlin-based musicians, dedicated to rock music.  🎸
          </p>
          <p className="main-text">
            Their first, self-titled EP was released in 2015, earning them local success. This was evident not least of all by the fact that even Star FM, Berlin's biggest rock radio station, began playing DinoSound’s songs and supporting their shows.
          </p>
          <p className="main-text">
            In 2017, the band started working on their first full-length album, "Claudia" , which they released in January 2018. The record was entirely self-produced, composed of eight songs that bring together all sorts of rock music from over the last 20 years. In playful, high-spirited music videos, like that for “Made of”, the band get to show off their personality, infused with a healthy sense of humor.
          </p>
          <p className="main-text">
            Since releasing "CLAUDIA“, DinoSound's powerful, infectious live performances have charmed audiences and earned the band a loyal following. It's no coincidence that they've already won several band competitions. If you get the chance to see DinoSound live — don't miss it!
          </p>
          <p className="main-text">
            Despite the Corona-related concert break, the four musicians of DinoSound do not let themselves be put to rest - the opposite is the case: the band understands the imbalance caused by the crisis as a reason to become active!
          </p>
          <p className="main-text">
            In their current video for the single LONG LONG GONE, DinoSound reflect the current situation of the concert and event industry and try to create awareness for the effects of the Corona crisis on the event sector in this way.
          </p>
          <p className="main-text">
            ✨✨✨✨✨✨✨✨✨✨✨✨✨✨✨✨✨✨
          </p>
        </div>
        <div>
          <img src={image1} alt="DinoSound Promo" loading="lazy" />
          <img src={image2} alt="DinoSound Band" loading="lazy" />
        </div>
      </div>
    </div>
  )
}

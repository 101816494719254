import Video1 from '../../assets/videos/live_1_possible.mp4'
import Poster1 from '../../assets/videos/poster_1.png'
import Video2 from '../../assets/videos/live_2_llg.mp4'
import Poster2 from '../../assets/videos/poster_2.png'
import Video3 from '../../assets/videos/live_3_secrets.mp4'
import Poster3 from '../../assets/videos/poster_3.png'
import Video4 from '../../assets/videos/live_4_disturbia.mp4'
import Poster4 from '../../assets/videos/poster_4.png'
import museekImg from '../../assets/images/epk/museekde.png'
import hammerImg from '../../assets/images/epk/metal_hammer.jpg'
import Video from "../../components/Video";

export default function Media(){

  return(
    <>
      <h2 className="h2" id="epk-media">Live Impressionen</h2>
      <div className="epk-videos">
        <Video src={Video1} poster={Poster1} width="240" />
        <div className="epk-videos__center">
          <Video src={Video2} poster={Poster2} width="360" />
          <Video src={Video3} poster={Poster3} width="360" />
        </div>
        <Video src={Video4} poster={Poster4} width="240" />
      </div>
      <h2 className="h2">Rezensionen und Presse</h2>
      <div className="epk-press-split">
        <div className="epk-press-split__item">
          <h2 className="main-h2">museek.de Januar 2020</h2>
          <a className="inline-link" href="https://www.museek.de/dinosound/" rel="noreferrer" target="_blank">
            <img src={museekImg} alt="DinoSound museek.de Promo" width="100%" className="epk-press-preview" loading="lazy" />
          </a>
        </div>
        <div className="epk-press-split__item">
          <h2 className="main-h2">Metal Hammer April 2018</h2>
          <a className="inline-link" href="/images/epk/metal_hammer.jpg" target="_blank">
            <img src={hammerImg} alt="DinoSound Metal Hammer Promo" width="100%" className="epk-press-preview" loading="lazy" />
          </a>
        </div>
      </div>
      <p className="main-text">Weitere Links:</p>
      <ul className="main-bullet-list">
      <li>
      <a href="https://gigradar.co.uk/discoverthis-dinosound/" className="inline-link">Interview with GigRadar UK</a>
      </li>
      <li>
      <a href="http://jazzandrock.com/?p=5945" className="inline-link">jazzandrock.com - Keine Zeit für Romantik: DinoSound lassen ihre Zuhörer atemlos zurück</a>
      </li>
      <li>
      <a href="https://www.stormbringer.at/reviews/14336/dinosound-claudia.html" className="inline-link">stormbringer.at - Review zum Debütalbum</a>
      </li>
      <li>
      <a href="http://nebendemstrom.blogspot.de/2018/01/11th-carrycoal-dinosound-claudia.html" className="inline-link">11th Carrycoal: DinoSound - Claudia</a>
      </li>
      </ul>
    </>
  )
}

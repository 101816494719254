import Header from "../../components/Header";
import MovingBanner from "../../components/MovingBanner";
import About from "./About";
import Contact from "./Contact";
import Music from "./Music";
import Videos from "./Videos";
import Dino from "./../../assets/images/dino_gif.gif"
import './styles.sass'

export default function Start(){
  return(
    <div className="main-page" id="epk-media">
      <Header />
      <div className="main-content">
        <MovingBanner text="New album in 2023!"/>
        <div className="giphy">
          <img src={Dino} alt="funny GIF" width="50%" loading="lazy" />
        </div>
        <Videos />
        <About />
        {/* <Music />
        <Contact /> */}
        <MovingBanner text="New album in 2023!"/>
      </div>
      <footer className="main-footer">
        <i className="fa-solid fa-envelope-o"></i>
        <a href="mailto:contact[at]dinosound-music.com" className="main-link main-fineprint">
          DinoSound
        </a>
        <span className="main-fineprint">-</span>
        <a href="/impressum/" className="main-link main-fineprint main-fineprint--impressum">Impressum</a>
      </footer>
    </div>
  )
}

import { epkNav, mainNav } from "../services/data"

interface NavigationProps{
  type?: string
}

export default function Navigation({type}: NavigationProps){
  const navItems = type === 'epk' ? epkNav : mainNav

  return(
    <ul className="main-nav">
      {navItems.map(item =>
        <li key={item.href} className="main-nav__item">
          <a href={item.href} className="main-link">
            {item.label}
          </a>
        </li>
      )}
    </ul>
  )
}

import { socialLinks } from "../../services/data";

export default function Contact(){
  return(
    <div id="epk-contact" className="epk-contact">
      <h1 className="h1">Kontakt</h1>
      <p className="main-text">
        <span>Florin Kerber</span>
        <span>
          E-Mail:  
          <a href="mailto:contact@dinosound-music.com" className="main-link">DinoSound </a>
          <i className="fa-solid fa-envelope-o"></i>
        </span>
        <span>Telefon: +49 175 16 36 750 <i className="fa-solid fa-phone"></i></span>
      </p>
      <ul className="epk-links">
        {socialLinks.map(link =>
          <li key={link.link} className="epk-links__item">
            <a className="inline-link" href={link.link} target="_blank" rel="noreferrer">
              <i className={`fa-brands ${link.icon}`}></i>
              <span>  {link.name}</span>
            </a>
          </li>
        )}
      </ul>
      </div>
  )
}

import { socialLinks } from "../services/data";

export default function SocialLinks(){
  return(
    <ul className="nav-social">
      {socialLinks.map(link =>
        <li key={`social-nav_${link.name}`} className="nav-social__item">
          <a className="inline-link" href={link.link} target="_blank" rel="noreferrer">
            <i className={`fa-brands ${link.icon}`}></i>
          </a>
        </li>
      )}
    </ul>
  )
}

export const socialLinks = [
  {name: 'Instagram', link: 'https://www.instagram.com/dinosound_berlin/', icon: 'fa-instagram'},
  {name: 'Spotify', link: 'https://open.spotify.com/artist/0TK50c63GunPi0UQjPKXIy?si=2FPcVHr1SjqfBm0180GEYA', icon: 'fa-spotify'},
  {name: 'Youtube', link: 'https://www.youtube.com/channel/UCLacASUFx_C4YdoQkdPHWAA', icon: 'fa-youtube'},
  {name: 'Facebook', link: 'https://www.facebook.com/dinosoundberlin/', icon: 'fa-facebook'},
  {name: 'Tiktok', link: 'https://www.tiktok.com/@dinosoundberlin', icon: 'fa-tiktok'}
]

export const links = [
  {name: 'Spotify', link: 'https://open.spotify.com/artist/0TK50c63GunPi0UQjPKXIy?si=2FPcVHr1SjqfBm0180GEYA'},
  {name: 'iTunes', link: 'https://music.apple.com/us/artist/dinosound/1332314012'},
  {name: 'Amazon', link: 'https://www.amazon.de/music/player/artists/B078VV7LLS/dinosound'},
  {name: 'Deezer', link: 'https://www.deezer.com/de/artist/11903107'},
  {name: 'Tidal', link: 'https://tidal.com/browse/artist/9414177'}
]

export const pain = [
  {name: 'Spotify', link: 'https://open.spotify.com/track/2EHC0FKrg7hkpoz9F9VwyO?si=0a96d85d0704447e'},
  {name: 'iTunes', link: 'https://music.apple.com/us/album/pain-single/1672267135'},
  {name: 'Amazon', link: 'https://amazon.de/music/player/albums/B0BVZP3VR2?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_Tu3p7Nl3Y0OkwqdSo6RtJbqK8'},
  {name: 'Deezer', link: 'https://deezer.page.link/NGuJXo7wURwncCsu6'},
  {name: 'Tidal', link: 'https://tidal.com/browse/album/277075268'}
]

export const disturbia = [
  {name: 'Spotify', link: 'https://open.spotify.com/intl-de/track/2O41YZroos9hyNChtJvXwr?si=1439785c1eb44052'},
  {name: 'iTunes', link: 'https://music.apple.com/us/album/disturbia-single/1684729722'},
  {name: 'Amazon', link: 'https://amazon.de/music/player/albums/B0C3PRSCCJ?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_NafPTdfLOgTq2iTfWHdY9hw5v'},
  {name: 'Deezer', link: 'https://deezer.page.link/HnQgLCexemCSoL5V6'},
  {name: 'Tidal', link: 'https://tidal.com/browse/album/291504632'}
]

export const stk = [
  {name: 'Spotify', link: 'https://open.spotify.com/track/3mHMBNl0L2BUO91gxbILQY?si=80988ff1626b4e33'},
  {name: 'iTunes', link: 'https://music.apple.com/us/album/the-secrets-they-keep/1677347935?i=1677347936'},
  {name: 'Amazon', link: 'https://amazon.de/music/player/albums/B0BYK8XBYQ?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE&ref=dm_sh_8tkgvVG3QvI2hxnrnQRtwJLj6'},
  {name: 'Deezer', link: 'https://deezer.page.link/ZUVBoepbz3TsLXgs7'},
  {name: 'Tidal', link: 'https://tidal.com/browse/album/281997977'}
]

export const videos = [
  {name: 'Disturbia', link: 'https://www.youtube.com/embed/p_MaO-pW3GA'},
  {name: 'The Secrets They Keep', link: 'https://www.youtube.com/embed/9Jw-kJ97s6U'},
  {name: 'Pain', link: 'https://www.youtube.com/embed/MxLtKluVvxs'},
  {name: 'Long Long Gone', link: 'https://www.youtube.com/embed/HM8gtF3SMT8'},
  {name: 'Easy', link: 'https://www.youtube.com/embed/-5-Y11T8dfQ'},
  {name: 'New Day', link: 'https://www.youtube.com/embed/4hbU5Q6Mdl8'},
  {name: 'Made Of', link: 'https://www.youtube.com/embed/umz4DZMUpHU'},
  {name: 'Ocean', link: 'https://www.youtube.com/embed/WvotJ72NoeI'}
]

export const mainNav = [
  {href: '/#videos', label: 'Videos'},
  // {href: './#music', label: 'Music'},
  {href: './#about', label: 'About'},
  // {href: './#contact', label: 'Contact'}
]

export const epkNav = [
  {href: '#epk-music', label: 'Musik'},
  {href: '#epk-media', label: 'Medien'},
  {href: '#epk-booking', label: 'Booking'},
  {href: '#epk-contact', label: 'Kontakt'}
]

import { videos } from "../../services/data";

export default function Videos(){
  return(
    <div id="videos">
      <h1 className="h1">Videos</h1>
      <div className="youtube-videos">
        {videos.map(video =>
          <>
            <h2 className="h2">{video.name}</h2>
            <iframe key={video.link} title={`Youtube Video ${video.name}`} className="youtube-videos__item" frameBorder="0" height="560" src={video.link} width="750"></iframe>
          </>
        )}
      </div>
    </div>
  )
}
